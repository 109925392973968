<template>
    <div  style="background-color: #fff">
        <carouselBanner paddingTop="24.5%" :bannerType="5"/>
        <div class="myConten">
            
            <div class="video">
                <div class="videoNav">
                    <div class="search">
                        <h1>视频</h1>
                    </div>
                    <Pagination v-if="videoList.length" :current-page='currentPage' :pageSize='limit' :total="total" @page='pageChange'></Pagination>
                </div>
                <div class="videoBox" v-loading='loading'>
                    <videoItem v-for="(item,i) in videoList" :key='i' :class="{isRight:(i+1)%5!=0}" :item='item' ></videoItem>
                    
                    <div class="nodataBox" v-if="!loading&&videoList.length==0">
                        <noDataImg  />
                    </div>
                    
                </div>
            </div>
        </div>
        
    </div>
</template>
<script>
import carouselBanner from '@/baseComponents/carouselBanner'
import videoItem from '~dlc/components/college/videoItem'
import Pagination from '@/baseComponents/Pagination'
export default {
    metaInfo: {
        title: "物贸学院",
    },
    components:{
        carouselBanner,
        videoItem,
        Pagination
    },
    data(){
        return{
            imgList:[
                'https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/trf/big_pic_1.png',
                'https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/trf/big_pic_2.png',
                'https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/trf/big_pic_3.png',
            ],
            videoList:[],
            currentPage:1,
			limit:15,
            total:0,
            loading:true
        }
    },
    mounted(){
        this.getList()
    },
    methods:{
        pageChange(e){
            console.log('oooo',e);
            this.currentPage=e
        },
        // 获取视频列表
        async getList(){ //
            this.loading=true
            let params={
                source:this.PJSource,
                start:this.currentPage-1,
                limit:this.limit
            }
            let list=await this.$store.dispatch('API_company/association_video_List', params);
            this.videoList=list.data
            this.total=list.total
            this.loading=false
            console.log(list);
        }
    }
    
}
</script>
<style lang="less" scoped>
    .myConten{
        width:1200px;
        margin: auto;
    }
    .xiubox{
        .tit{
            height: 33px;
            font-size: 24px;
            font-weight: 600;
            color: #192F59;
            line-height: 33px;
        }
        .scrollBox{
            display: flex;
            flex-wrap:nowrap ;
            height: 300px;
            width: 1200px;
            img{
                width: 220px;
                height: 325px;
                // box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.21);
                border-radius: 10px;
            }
        }
    }
    .video{
        .videoNav{
            padding-top:36px;
            padding-bottom: 24px;
            display: flex;
            justify-content: space-between;
            .search{
                h1{
                    height: 33px;
                    font-size: 24px;
                    font-weight: 600;
                    color: #2d8bce;
                    line-height: 33px;
                }
            }
        }
        .videoBox{
            display: flex;
            flex-wrap: wrap;
            min-height: 400px;
            .nodataBox{
                height: 400px;
                width: 200px;
                margin: auto;
                position: relative;
                text-align: center;
            }
        }
        .isRight{
            margin-right: 25px;
        }
    }
</style>